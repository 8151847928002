import { formMixin } from "@/mixins/formMixin.js"

export const collectionsOperation = {
  mixins: [formMixin],
  methods: {
    async commonDeleteItem(item, mainArray, collectiondb, deletemsg) {
      console.log("inside commonDeleteItem ",item)
      var returmsg = ""
      const index = mainArray.indexOf(item)

      const option = await this.deleteFromFb(deletemsg)
      if (option) {
        try {
          await collectiondb.doc(item.uid).delete()
          await mainArray.splice(index, 1)
          // this.genralmsg("the asset has been deleted")
        } catch (error) {
          returmsg = "Error while delete :"+error
          return returmsg
        }
      }
      return true
    },
    updaterealtimedata(parentArray, newObject) {
      var objIndex = parentArray.findIndex((obj) => obj.uid == newObject.doc.id)
      //console.log("inside updaterealtimedata",objIndex,newObject.doc.id,newObject.doc.data())
      if (newObject.type === "modified") {
       // parentArray[objIndex]={};
        Object.assign(parentArray[objIndex], newObject.doc.data())
        parentArray[objIndex].uid=newObject.doc.id
      } else if (newObject.type === "added") {
        if (objIndex < 0)
          parentArray.push({
            ...newObject.doc.data(),
            uid: newObject.doc.id,
          })
      } else if (newObject.type === "removed") {
        parentArray.splice(objIndex, 1)
      }

      return
    }, //end of updaterealtimedata
    async loadInitialData(collectionName, domainName, mainArray) {
      try {
        await collectionName
          .where("domain", "==", domainName)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              var objIndex = mainArray.findIndex((obj) => obj.uid == doc.id)
            //  console.log("inside loadInitialData",objIndex,doc.id,doc.data().name)
              if (objIndex < 0) {
                mainArray.push({
                  ...doc.data(),
                  uid: doc.id,
                })
              } //end of objIndex
            })
          })
        return true
      } catch (err) {
        var errorMessage = "Error while get the data" + err
        return errorMessage
      }
    }, //loadInitialData

    async updatecollection(collectionName, mainArray, updatedItem) {
      try {
        await collectionName.doc(updatedItem.uid).set({
          ...updatedItem,
        })
        var objIndex = mainArray.findIndex((obj) => obj.uid == updatedItem.id)
        if (objIndex > -1) {
          mainArray[objIndex] = Object.assign({}, updatedItem)
        }
        return true
      } catch (err) {
        var errorMessage = "Error while add/update the data" + err
        return errorMessage
      }
    }, // end of updatecollection

    async addcollection(collectionName, mainArray, newItem) {
      var retMessgage
      await collectionName
        .add(newItem)
        .then((docRef) => {
          newItem.uid = docRef.id
          /*  var objIndex=mainArray.findIndex(obj=> obj.uid==newItem.uid)
            console.log("objIndex",objIndex)
              if(objIndex<0)*/

         // mainArray.push(newItem)
          console.log("newItem",newItem)
          retMessgage = true
        })
        .catch((err) => {
          console.log("Error in addcollection", err)
          retMessgage = "Error while add the data" + err
        })
      return retMessgage
    }, // end of addCollections
  }, // end of methods
}
